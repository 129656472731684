@font-face {
  font-family: 'Plus Jakarta Sans';
  src: local('Plus Jakarta Sans'), 
       url('../fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
body {
  margin: 0;
 font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.index_controls__sXDjs {
    text-align: left;
padding: 10px;
    position: relative;
    top: 0 !important;
    margin-left: 4px;
}

.primary-large-text{
    font-size: 70px;
    letter-spacing: 0em;
    text-align: left;
}
.secondry-large-text{
    font-size: 70px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}


.landing-header-conatiner{
  
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color)
  
}
.header-container{
  border-bottom: 1px solid #017DF9;
  a{
    text-decoration:none;
font-size: 16px;
font-weight: 400 !important;
line-height: 24px !important;
letter-spacing: 0em;
text-align: left;
color: #E2E2E2 !important;
  }
  
}
.intro-box-shadow{
  box-shadow: inset -6px 12px 20px 0px rgba(1, 125, 249, 0.25);
  
}
.app-primary-text{
  color: var(--primary-text-color);
}
.app-secondary-text{
  color: var(--secondary-text-color);
}
.app-disable-text{
  color: var(--disabled-text-color);
}

.index_terminal__teubZ{
      background: rgb(21 21 21) !important;
}
.index_editor__JoDSg{

  span{

       color: var(--terminal-text-color) !important;

  }
}