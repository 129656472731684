:root {
    --font-family: "Plus Jakarta Sans", sans-serif;
    --primary-color: #017DF9;
    --secondary-color: #e50515;
    --border-color:  #017DF9CC;
      --primary-text-color: #017DF9;
  --secondary-text-color: #E2E2E2;
  --disabled-text-color:#919EAB;
   --terminal-text-color:rgb(87 192 56)

}